<template src="./AfishaByTID.html"></template>

<script>

import store from "@/store";
import {AUTH_REQUEST} from "@/store/actions/auth";

export default {
  name: "AfishaByTID",
  data: () => ({

    theater:0,

    pushka: false,
    groupByShow: false,

    events:[],
    events_loaded:false,

  }),

  created() {



    console.log(this.$route.params);

    if (this.$route.params.tid) {

      // входим под учеткой в этот театр
      const username = "api_user@zakaz.cloud";
      const password = "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo";
      const isAdmin = false;
      const theater_select = this.$route.params.tid;
      this.theater = this.$route.params.tid;

      //console.log('before');

      store.dispatch(AUTH_REQUEST, { username, password, theater_select, isAdmin }).then(() => {
        //this.$router.push("/buy/12");

        console.log('ttttt');

        console.log(this.$route.params.all);

        switch (this.$route.params.all){

          case "all":
            // открываем всех
            this.loadEvents();
            this.pushka = false;
            this.groupByShow = false;
            break;

          case "group":
            // не пушка, группировать
            this.loadEvents(false, true);
            this.pushka = false;
            this.groupByShow = true;
            break;


          default:
            // открываем пушку
            this.loadEvents(true);
            this.pushka = true;
            this.groupByShow = false;
            break;
        }



      });

      console.log('after');






    }
    else{
      console.log('нет театра нет входа')
      return '/none'
    }


  },

  computed:{


  },

  mounted() {

    console.log('mount');
  },



  methods:{

    stripslash(str){
      return str.replace(/\\/g, '');
    },


    loadEvents(isPushka, isGroupByShow = false){

      let filter = {
        status: 32
      }

      if (isPushka){
        console.log('pushka!');
        filter.pushka = 1;
      }

      let param = {
        sort:'timedate',
        desc:false,
        limit:500,
      }

      if (isGroupByShow){
        param.group = "show_id";
        param.group_sort = "timedate";
      }


      const post_data = {

        global:{
          format:"raw",
          action:"find",
          module:"event"
        },

        data:{

          mode: {
            target:     "coming",
            date_field: "timedate"
          },

          filter: filter,

          fields:{
            id:1,
            pushka:1,
            show_id:1,
            timedate:1,
            status:1,
            prices:1,
            free_seat:1,
          },

          param: param,

          detail:{
            show_id:{
              fields:{
                id:1,
                name:1,
                age:1,
                time:1,
                culture_id:1,
              }
            }
          }
        }
      };

      console.log(post_data);

      let pr2 = this.$store.state.api.findEvents(post_data);

      pr2.then( data =>{
        //console.log(data);
        this.events = data;
        this.events_loaded = true;
      })




    },

    onEventClick(id){

      console.log(this.$route.params.tid, id);

      let url = "/b/"+this.$route.params.tid+"/"+id;

      this.$router.push(url);

    },


    onShowClick(id){

      console.log(this.$route.params.tid, id);

      let url = "/bg/"+this.$route.params.tid+"/"+id;

      this.$router.push(url);

    }







  }
}



</script>

<style scoped>


.show{
  margin: 25px auto;
  padding: 10px;
  width:600px;
  background-color: #b5d6ff;
  border-radius: 5px;
  font-size:2rem;
  color:black;
  text-align: center;
  cursor: pointer;
}
.show:hover{

  background-color: #4abcff;
}

.pushka{
  background-color: #ffb6f3;
}

.sold_out{
  background-color: #c1c1c1;
}


a{
  color:black;
  text-decoration: none;
}

</style>
